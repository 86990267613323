#outlet-details {
  padding-bottom: 10px;
  background-color: #f2f2f2;
}

#outlet-details h4 {
  text-transform: uppercase;
  color: #1c3c6c;
}

#phone-image {
  width: 100%;
  max-width: 75px;
  margin-top: 15px;
}

#phone-container {
  vertical-align: middle;
}