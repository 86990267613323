.fitment-header {
  padding: 10px 0;
  text-transform: uppercase;
  color: #1c3c6c;
}

.modal-confirm {
  color: #636363;
}
.modal-confirm, .modal-body {
  padding: 0 !important;
}
.modal-confirm .modal-content {
  /* border-radius: 5px;
  border: none; */
  text-align: center;
  font-size: 14px;
}
.modal-confirm .modal-header {
  border-bottom: none;
  position: relative;
}
.modal-confirm h4 {
  text-align: center;
  font-size: 26px;
}
.modal-confirm .close {
  position: absolute;
  top: 5px;
  right: 5px;
}
.modal-confirm .modal-body {
  color: #999;
}
.modal-confirm .modal-footer {
  border: none;
  text-align: center;
  border-radius: 5px;
  font-size: 13px;
}
.modal-footer-location {
  padding: 15px;
  text-align: right;
  border-top: 1px solid #e5e5e5;
  width: 31rem !important;
}
.modal-confirm .modal-footer a {
  color: #999;
}
.modal-confirm .icon-box {
  margin: 0 auto;
  border-radius: 50%;
  z-index: 9;
  text-align: center;
}
.modal-confirm .icon-box i {
  color: #fcb823;
  font-size: 46px;
  display: inline-block;
  margin-top: 13px;
}
.modal-confirm .btn {
  color: #fff;
  border-radius: 4px;
  background: #60c7c1;
  text-decoration: none;
  transition: all 0.4s;
  line-height: normal;
  border: none;
  min-height: 40px;
  border-radius: 3px;
  margin: 0 5px;
  outline: none !important;
}
.btn {
  color: #fff;
  border-radius: 4px;
  background: #60c7c1;
  text-decoration: none;
  transition: all 0.4s;
  line-height: normal;
  border: none;
  min-height: 40px;
  border-radius: 3px;
  margin: 0 5px;
  outline: none !important;
}
.modal-confirm .btn-info {
  background: #c1c1c1;
  color: black;
  width: 10rem;
}
.btn-succ {
  width: 10rem;
  background-color: #1c3c6c  !important;
}
.modal-confirm .btn-info:hover,
.modal-confirm .btn-info:focus {
  background: #a8a8a8;
}
.modal-confirm .btn-danger {
  background: #1c3c6c;
}
.modal-confirm .btn-danger:hover,
.modal-confirm .btn-danger:focus {
  background: #1c3c6c;
}
.trigger-btn {
  display: inline-block;
  margin: 100px auto;
}

.geosuggest {
  font-size: 18px;
  font-size: 1rem;
  position: relative;
  width: 50%;
  margin: 1em auto;
  text-align: left;
}
.geosuggest__input {
  width: 100%;
  border: 2px solid transparent;
  box-shadow: 0 0 1px #3d464d;
  padding: .5em 1em;
  -webkit-transition: border 0.2s, box-shadow 0.2s;
          transition: border 0.2s, box-shadow 0.2s;
}

.geosuggest__suggests {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  max-height: 25em;
  padding: 0;
  margin-top: -1px;
  background: #fff;
  border-top-width: 0;
  overflow-x: hidden;
  overflow-y: auto;
  list-style: none;
  z-index: 5;
  -webkit-transition: max-height 0.2s, border 0.2s;
          transition: max-height 0.2s, border 0.2s;
}
.geosuggest__suggests--hidden {
  max-height: 0;
  overflow: hidden;
  border-width: 0;
}

/**
 * A geosuggest item
 */
.geosuggest-item {
  padding: 1em;
  cursor: pointer;
  border-top: 1px dotted #ccc;
}
.geosuggest-item:hover,
.geosuggest-item:focus {
  background: #f5f5f5;
}

.geosuggest-item--active:hover,
.geosuggest-item--active:focus {
  background: #ccc;
}

.geosuggest-item__matched-text {
  font-weight: bold;
}
.reset-location-btn {
  width: 100%;
    text-align: center;
    display: flex;
    /* align-content: stretch; */
    justify-content: center;
}

.btn-succuss-footer {
  width: 18rem;
  background-color: #1c3c6c  !important;
}

.btn-info-footer {
  background: #c1c1c1;
  color: black;
  width: 10rem;
}