#updates h4 {
  padding: 10px 0;
  color: #1c3c6c;
  text-transform: uppercase;
}

.comment {
  text-align: left;
}

.comment-header {
  color: #1c3c6c;
}