.rounded-corners {
  border-radius: 5px;
  overflow: hidden;
}

.take-photo-button {
  border: 1px solid #ccc;
  padding: 0;
}

.take-photo-button:before {
  content:'';
  padding:50% 0; /* vertical value as  100% equals width */
  display:inline-block;
}

.photo {
  width: 100%;
  height: 250px;
}

.photo:before{
  content:'';
  padding:50% 0; /* vertical value as  100% equals width */
  display:inline-block;
}

.updateSteps::before {
    /* Decrement "my-sec-counter" by 1 */
    counter-increment: my-sec-counter -1;
    content: "Picture " counter(my-sec-counter) " ";
}

.img-card {
  background: #fff;
  border-radius: 2px;
  display: inline-block;
  height: 100%;
  position: relative;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  margin-bottom: 10px;
  color: black;
  
}
.img-title {
  position: relative;
  font-family: sans-serif;
  top: 10px;
  font-weight: 900;
  font-size: 14px;
  color: #6e6e6e;
  font-family: var(--font-family-base);
}

.newBtn {
  appearance: none;
  -webkit-appearance: none;
  font-family: sans-serif;
  cursor: pointer;
  padding: 8px;
  width: 100%;
  /* height: 39px; */
  border-style: solid;
  border-radius: 10px;
  border-width: 2px;
  background-color: white !important;
  color: #0c4f94 !important;
  /* border: 175px; */
  box-shadow: 25px black;
  border-style: solid;
  transition: background-color 100ms linear;
}

.newBtn2 {
  appearance: none;
  -webkit-appearance: none;
  font-family: sans-serif;
  cursor: pointer;
  padding: 8px;
  width: 80%;
  margin-left: 10%;
  /* height: 39px; */
  border-style: solid;
  border-radius: 10px;
  border-width: 2px;
  background-color: white !important;
  color: #0c4f94 !important;
  /* border: 175px; */
  box-shadow: 25px black;
  border-style: solid;
  transition: background-color 100ms linear;
}

.newBtn:hover{
  color: black !important;
  box-shadow: 25px black !important;
  border-style: solid !important;
  transition: background-color 100ms #fcb823 !important;
}

.newBtn2:hover{
  color: black !important;
  box-shadow: 25px black !important;
  border-style: solid !important;
  transition: background-color 100ms #fcb823 !important;
}

@media only screen and (max-width: 600px) {
  #ComplainFooter {
    width: 16%;
  }
  #CallAgentFooter {
    width: 16%;
  }
}
@media only screen and (max-width: 400px) {
  #ComplainFooter {
    width: 14%;
  }
  #CallAgentFooter {
    width: 14%;
  }
  .btn-succuss-footer {
    width: 16rem !important;
    background-color: #1c3c6c !important;
}
}

