#complaints-header {
  text-transform: uppercase;
  color: #1c3c6c;
  margin: 20px 0;
}

#complaints-buttons {
  padding-top: 15px;
  color: #1c3c6c;
}

#complaints-buttons img {
  max-width: 150px;
  width: 100%;
}

#request-contact h4 {
  text-transform: uppercase;
  color: #1c3c6c;
  margin: 20px 0;
}

.table {
  border-spacing:0 20px;
}


.header{
  cursor: pointer;
  border: solid 1px #fcb823;
  padding: 10px;
  background-color: #fcb823;
  color: black;
  font-family: verdana;
  text-align: left;
  font-weight: 500;
  font-size: 14px !important;
  position: relative;
  }
  
  .content{
  cursor: pointer;
  border-left: solid 1px #f2f2f2;
  border-right: solid 1px #f2f2f2;
  border-bottom: solid 1px #f2f2f2;
  border-radius: 0 0 5px 5px;
  padding: 15px;
  font-family: verdana;
  font-size: 14px;
  }

  #section1 > h1 {
    color: blue;
  }
  
  #section2 > h1 {
    color: red;
  }

  .grey-cell {
    margin-top: -20px;
  }
  body {
    margin-top: 10rem !important;
  }

  .faq-section .mb-0 > a {
    display: block;
    position: relative;
  }
  
  .faq-section .mb-0 > a:after {
    content: "\f067";
    font-family: "Font Awesome 5 Free";
    position: absolute;
    right: 0;
    font-weight: 600;
  }
  
  .faq-section .mb-0 > a[aria-expanded="true"]:after {
    content: "\f068";
    font-family: "Font Awesome 5 Free";
    font-weight: 600;
  }
  .location-error {
    text-align: center;
    color: rgb(255, 115, 0);
    position: relative;
    bottom: 10px;
    font-weight: 900;
  }
