#vehicle-header {
  padding: 10px 0;
  text-transform: uppercase;
  color: #1c3c6c;
}

#make-model-confirm-header, #licence-disk-header {
  padding: 10px 0;
  text-transform: uppercase;
  color: #1c3c6c;
}

#make-model-confirm-status {
  text-transform: capitalize;
}
