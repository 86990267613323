.header-button {
  margin-top: '1em',
}

.footer-icon {
  margin-top: 5px;
  width: 50px;
}

.footer-label {
  text-transform: uppercase;
  color: #fff;
  font-family: var(--font-family-base);
}