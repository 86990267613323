#rate-us {
  margin-bottom: 20px;
}

#rate-us-header {
  padding: 10px 0;
  color: #1c3c6c;
  text-transform: uppercase;
  text-align:  left;
}

#complaints-buttons {
  padding-top: 15px;
  color: #1c3c6c;
}

#complaints-buttons img {
  max-width: 150px;
  width: 100%;
}