.circle {
  color: #e6e6e6;
}

.circle-selected {
  color: #fcb823;
}

.status {
  color: #e6e6e6;
  text-transform: uppercase;
}

.status-selected {
  color: #1c3c6c;
  text-transform: uppercase;
}