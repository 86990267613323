#fitment-options-table{
  width: 100%;
}

#fitment-options-table *{
  margin: 5px;
  color: black;
  font-size: 12px;
}

.btn-lg {
  color: white !important;
}