#verify-reg-form {
  margin-top: 15px;
}

#company-logo {
  width: 100%;
  max-width: 300px !important;
}

.get-help {
  width: 100px;
  border: 0;
}

.reg-verify {
  width: 2.5em;
  display: inline-block;
  margin: 0 1px;
  padding: 6px 6px;
  text-align: center;
}

.center-block {
  display: block;
  margin-right: auto;
  margin-left: auto;

}