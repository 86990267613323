.App-header {
  /* background-color: #f2f2f2; */
  /* text-align: center;
  padding: 10px 0; */
  /* margin-top: 15px; */
  /* top: 0;
  width: 100%;
  background-color: #f2f2f2;
  z-index: 10; */
  /* text-align: center;
  padding: 10px 0;
  top: 0;
  width: 100%;
  background-color: #f2f2f2;
  z-index: 10; */
  background-color: #f2f2f2;
  margin-top: -102px;
  text-align: center;
  padding: 10px 0;
  z-index: 10;
  
}

header #company-logo {
  width: 100%;
  max-width: 300px;
}

.App-header div {
  /* width: 100%; */
  /* text-align: center;
  padding: 10px 0;
  top: 0;
  width: 100%;
  background-color: #f2f2f2;
  z-index: 10; */
}

