#camera {
  position: relative;
}

video, canvas {
  width: 320px;
  height: 320px;
  display: none;
  margin: auto;
}

#pick-image {
  display: none;
}

#overlay {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  opacity: 0.3;
  width: 320px;
  height: 320px;
  padding-top: 50px;
  background-color: #fff;
  margin: auto;
  margin-bottom: 0;
  margin-top: 0;
}

#overlay img {
  width: 100%;
}

.video {
  margin-top: 25px;
}

#player {
  margin-top: 25px;
}

.camera-btn {
  background-color: rgb(51, 51, 51) !important;
  display: inline-block !important;
  border-radius: 50% !important;
  width: 8rem !important;
  height: 8rem !important;
  text-align: center !important;
  color: white !important;
  position: relative;
  bottom: 20px;
}