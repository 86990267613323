#top-nav {
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  background-color: #4d4d4d;
  color: #fcb823;
}

#top-nav .active {
  background-color: #fcb823;
  color: #4d4d4d;
}